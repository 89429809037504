import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { NextPage } from 'next';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useAuthContext } from '@/contexts/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  bannerChip: {
    marginRight: theme.spacing(2),
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%',
    },
  },
}));

const LoginPage: NextPage = () => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm({
    defaultValues: { username: '', password: '' },
  });
  const { login } = useAuthContext();
  const onSubmit = handleSubmit((data) => {
    login(data.username, data.password);
  });
  return (
    <>
      <Container className={classes.cardContainer} maxWidth="sm">
        <Card>
          <CardContent className={classes.cardContent}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h2">
                  Sign in
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Sign in to CPMIS Admin panel
                </Typography>
              </div>
            </Box>
            <Divider orientation="horizontal" />
            <Box flexGrow={1} mt={3} component="form" onSubmit={onSubmit}>
              <TextField
                margin="normal"
                label="Username"
                fullWidth
                variant="outlined"
                name="username"
                inputRef={register({ required: true })}
              />
              <TextField
                margin="normal"
                label="Password"
                fullWidth
                variant="outlined"
                name="password"
                inputRef={register({ required: true })}
                type="password"
              />
              <Box mt={2}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Sign in
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default LoginPage;
